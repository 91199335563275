@font-face {
  font-family: 'DINRegular';
  src: local('DINRegular'),
    url("./Fonts/5563279/6b4d4830-9c7d-43d2-b5d3-c73f739561b9.woff2") format("woff2"),
    url("Fonts/5563279/ccf25ada-6d2a-4133-95dc-deb039e22999.woff") format("woff");
}

body {
  margin: 0;
  font-family: 'DINRegular';
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  font-family: 'DINRegular';
}