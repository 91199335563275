@media print {
    .no-print {
        visibility: hidden;
    }
}

.summary-root {
    margin: 25px;
    border: 1px solid grey;
    padding: 20px;
    overflow: auto;
    /* height: 70vh; */
}

.top-left {
    width: 12%;
}

.top-center {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 10px;
}

.dotted-line {
    border: 2px dotted black;
}

.delivery-and-donor {
    display: flex;
    margin-top: 15px;
}

.delivery-instructions {
    flex: 4;
}

.donor-details {
    flex: 1;
    height: fit-content;
}

.items-table, .notes-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
  
.items-table td, .items-table th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.items-table tr:nth-child(even) {
    background-color: #dddddd;
}

.subCategory {
    width: 100%;
    font-weight: bold;
    margin-left: 5rem;
    margin-top: 3.5rem;
}

.basicsummary-button {
    margin: auto;
}

.donation-summary-qr-code {
    
}