.recipient-info {
    width: 20rem;
    margin: 10px;
    border: 1px solid gray;
    padding: 25px;
    border-radius: 10px;
}

.request-summary-flexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.request-summary-root {
    margin: 50px;
}