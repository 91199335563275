.center {
    margin: auto;
    width: 50%;
    /* border: 3px solid green; */
    padding: 10px;
}

.view-summary-button {
    margin-top: 5px;
    margin-bottom: 10px;
}

.table {
    max-width: 90vw;
    margin: auto;
    /* width: 50%; */
}

.request-history-filters {
    /* max-width: 90vw; */
    /* margin-top: 2rem; */
    /* margin-bottom: 2rem; */
    /* margin: 1rem 0 1rem 5vw; */
    margin-left: 5vw;
    margin-bottom: 2rem;
}

.summary-header {
    text-align: center;
}

/* Solid border */
hr.solid {
    border-top: 3px solid #bbb;
  }