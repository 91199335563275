.menu-donation-history {
    margin-right: 20px !important;
}

.menu-buttons {
    display: flex;
    align-items: center;
}

.menu-home {
    margin-right: 20px !important;
}

.MuiDrawer-paper {
    width: 60%;
}

/* .barlow-font {
    font-family: 'Barlow', sans-serif;
} */