.grid {
    padding: 15px;
    /* margin-left: 20px; */
    /* margin-right: 20px; */
    max-width: 90vw;
    margin: auto;
    align-items: center;
    /* display: none; */
}
/* .gridItem {
    height: 100%;
}  */

.modal-bio {
    font-size: 1.5rem;
    font-weight: bold;
}
/* @media (max-width: 550px) {
} */

/* Modal Styles */
.modal-root {
    margin-top: 10vh;
    background-color: white;
    height: auto;
    width: 60vw;
    margin-left: 20vw;
    overflow: scroll;
}
.modal-body {
    background-color: white;
    /* display: flex;
    flex-wrap: wrap; */
    height: 100%;
    margin-top: -25px;
    padding: 20px;
    margin: auto;
    overflow: hidden;
}
.modal-body h2 {
    font-weight: bold;
}
.modal-body img {
    width: 100%;
    max-width: 50vw;
}

.card-donate > a {
    cursor: pointer;
}
.modal-title {
    /* max-width: fit-content; */
    flex-grow: 1;
}
.flex-child2 {
    width: 100%;
    max-width: 40vw;
    padding: 15px;
    margin-left: 10px;
}

/* added new */
/* .MuiCardContent-root {
    height: 5rem;
} */
.dropdown-item {
    width: 8rem;
    /* width: max-content; */
}
.dropdown {
    display: flex;
    /* justify-content: space-evenly; */
    flex-wrap: wrap;
}
.formControl {
    margin: 1rem 1rem 0 1rem !important;
}
.donate-modal {
    background-color: rgba(52, 211, 153, 1) !important;
    /* margin-top: 5rem !important; */
    /* margin-left: 1rem !important; */
}

.modal-buttons-container {
    /* display: flex;
    justify-content: space-around;
    margin: auto;
    margin-top: 7rem;
    width: min(50vw,600px); */
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    margin-top: 7rem;
    margin-bottom: 4rem;
    max-width: 800px;
    flex-wrap: wrap;
}

.MuiCardContent-root {
    height: auto;
}

/* .MuiTypography-colorTextSecondary {
    margin-top: 0px;
} */

.card-items {
    margin-top: 10px;
}

.instructions-card {
    max-width: 80vw;
    margin: auto;
    /* text-align: center; */
    height: auto;
    margin-top: 15px;
}


.row {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap; */
    /* width: 50%; */
    /* width: fit-content; */
}
  
.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.filter {
    padding: 10px;
    margin-left: 2%;
    margin-top: 2rem;
    max-width: 90vw;
    /* display: none; */
    /* display: flex; */
    justify-content: center;
    /* flex-wrap: wrap; */
}
.filter-item {
    margin: 20px 20px 0 20px;
    flex-grow: 1;
}

.bold-text {
    font-weight: bold;
}

.donateImg {
    height: 300px;
    /* width: 50vw; */
    margin-left: 10vw;
}

.donationHeroContent {
    display: flex;
}

.Pagetitle {
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
    margin: 8px;
}

@media print {
    .noPrint {
        visibility: hidden;
    }
}

.greenButton {
    background-color: rgba(52, 211, 153, 1);   
}

.itemCategory {
    width: 100vw;
    font-weight: bold;
    margin-top: 3.5rem;
}

.banner-image {
    width: 100vw;
    /* max-width: 100vw; */
}

.donationInstructionsText {
    margin-bottom: 25px;
}

.donation-card-link {
    cursor: pointer;
}

.itemCard {
    /* height: 450px; */
}

.itemGrid {
    margin: auto;
    max-width: 85vw;
}

.itemCardDescription {
    height: 20px;
}

.itemCardAction {
    margin: 3%;
}

.itemCardProductImage {
    width: 100%;
    height: auto;
    margin: auto;
}