.heroImage {
    width: 18vw;
    margin-bottom: -50px;
}

@media (max-width: 860px) {
      .heroImage {
        display: none;
      }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.gridItem {
  /* height: 100%; */
}

.swiper-slide-active {
  margin: auto;
}

.swiper-button-prev {
  left: -2px !important;
  /* display: none; */
  /* color: black; */
}
.swiper-button-next {
  right: -1px !important
}

body {
  /* background-color: aliceblue; */
}

.swiper-container {
  /* overflow: visible; */
}

.card-bio {
  font-size: medium;
}

.card-items {
  font-size: medium;
}

@media (max-width: 1200px) {
  .card-bio {
    font-size: small;
  }
  
  .card-items {
    font-size: small;
  }
}
